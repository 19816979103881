<template>
  <div class="approval-page">
    <div class="approval-page__wrapper">
      <div class="page_title">
        <p class="pt-12 pb-4">{{ orgDetails.orgname }} / Vendor Onboarding</p>
      </div>
      <div class="contianerx">
        <div
          class="rounded"
          style="border-top-left-radius: 10px; border-top-right-radius: 10px"
        >
          <div class="d-flex px-4">
            <v-tabs
              @change="viewActiveTab"
              v-model="tab"
              background-color="#ffffff"
              slider-size="4"
            >
              <v-tab v-for="item in VendorTabs" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
          </div>
          <div class="d-flex px-8 py-4" style="background: #f6f3ee">
            <p class="info_text">
              {{ orgDetails.orgname }} has invited you to complete onboarding
              your business as a vendor/supplier
            </p>
          </div>
          <v-form ref="inviteVendorForm">
            <v-tabs-items v-model="tab" class="px-4 rounded-b-lg">
              <v-tab-item class="py-7">
                <v-row class="mx-4">
                  <v-col md="6" class="pl-0"
                    ><v-text-field
                      v-model="vendorDetails.contact.first_name"
                      :rules="rules.first_name"
                      ref="first_name"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Your First Name"
                      label="First Name"
                    ></v-text-field
                  ></v-col>
                  <v-col md="6" class="pr-0"
                    ><v-text-field
                      v-model="vendorDetails.contact.last_name"
                      :rules="rules.last_name"
                      ref="last_name"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Your Last Name"
                      label="Last Name"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row class="mx-4">
                  <v-col md="6" class="pl-0"
                    ><v-text-field
                      :rules="rules.email"
                      ref="email"
                      v-model="vendorDetails.contact.email"
                      background-color="#ffffff"
                      type="email"
                      outlined
                      hide-details="auto"
                      placeholder="Email"
                      label="Business Email"
                    ></v-text-field
                  ></v-col>
                  <v-col md="6" class="pr-0"
                    ><v-text-field
                      v-model="vendorDetails.contact.phone"
                      :rules="rules.phone"
                      ref="phone"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Phone"
                      label="Business Phone"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row class="mx-4">
                  <v-col cols="12" class="px-0">
                    <v-text-field
                      v-model="vendorDetails.address"
                      label="Full Business Address"
                      :rules="rules.address"
                      ref="address"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Address"
                    >
                    </v-text-field
                  ></v-col>
                </v-row>
                <div class="d-flex justify-end py-6 px-4">
                  <v-btn
                    @click="switchTabs('next')"
                    dark
                    width="121"
                    height="45"
                    style="
                      background: var(--v-primary-base);
                      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.24);
                      border-radius: 4px;
                    "
                  >
                    <simple-line-icons
                      icon="arrow-right"
                      color="#FFFFFF"
                      style="
                        font-family: simple-line-icons;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 16px;
                      "
                      no-svg
                    />
                    <span
                      class="text-capitalize pl-3"
                      style="
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: center;
                        letter-spacing: 0.636364px;
                        color: #ffffff;
                      "
                      >next</span
                    >
                  </v-btn>
                </div>
              </v-tab-item>
              <!-- BUSINESS TAB -->
              <v-tab-item class="py-7">
                <v-row class="mx-4">
                  <v-col class="px-0"
                    ><v-text-field
                      v-model="vendorDetails.vendorname"
                      :rules="rules.vendorname"
                      ref="vendorname"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Company Name"
                      label="Full Company Name"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row class="mx-4">
                  <v-col md="6" class="pl-0"
                    ><v-select
                      v-model="vendorDetails.terms"
                      :items="paymentTerms"
                      :rules="rules.terms"
                      ref="terms"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Payment Terms"
                      label="Select Payment Terms"
                    ></v-select
                  ></v-col>
                  <v-col md="6" class="pr-0"
                    ><v-text-field
                      :rules="rules.zip"
                      ref="zip"
                      v-model.number="vendorDetails.zip"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Post Code"
                      label="Enter Post Code"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <v-row class="mx-4">
                  <v-col md="6" class="pl-0"
                    ><v-text-field
                      v-model="vendorDetails.city"
                      :rules="rules.city"
                      ref="city"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="City"
                      label="City"
                    ></v-text-field
                  ></v-col>
                  <!-- temporary adjustments starts here -->
                  <v-col md="6" class="pr-0"
                    ><v-text-field
                      v-model="vendorDetails.state"
                      :rules="rules.state"
                      ref="state"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="State"
                      label="State"
                    ></v-text-field
                  ></v-col>
                </v-row>

                <div class="d-flex justify-end py-6 px-4">
                  <v-btn
                    @click="switchTabs('next')"
                    dark
                    width="121"
                    height="45"
                    style="
                      background: var(--v-primary-base);
                      box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
                      border-radius: 4px;
                    "
                  >
                    <simple-line-icons
                      icon="arrow-right"
                      color="#FFFFFF"
                      style="
                        font-family: simple-line-icons;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 16px;
                      "
                      no-svg
                    />
                    <span
                      class="text-capitalize pl-3"
                      style="
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: center;
                        letter-spacing: 0.636364px;
                        color: #ffffff;
                      "
                      >next</span
                    >
                  </v-btn>
                </div>
              </v-tab-item>
              <!-- CATEGORY TAB -->
              <v-tab-item class="py-7">
                <v-row class="mx-4">
                  <v-col md="6" class="pl-0"
                    ><v-select
                      ref="vendor_type"
                      :rules="rules.vendor_type"
                      v-model="vendorDetails.WHT.vendor_type"
                      :items="businessCategory"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Business Category"
                      label=""
                    ></v-select
                  ></v-col>
                  <v-col md="6" class="pr-0"
                    ><v-select
                      ref="category"
                      :rules="rules.category"
                      v-model="vendorDetails.WHT.category"
                      :items="taxCategory"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      placeholder="Tax Category"
                    ></v-select
                  ></v-col>
                </v-row>
                <v-row class="mx-4">
                  <v-col md="6" class="pl-0">
                    <v-select
                      class="mt-0"
                      v-model="vendorDetails.currency"
                      :items="currency"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      :rules="rules.currency"
                      ref="currency"
                      placeholder="Currency"
                    ></v-select>
                  </v-col>
                  <v-col md="6" class="pr-0">
                    <v-select
                      v-model="vendorDetails.business_category"
                      :items="industry"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      :rules="rules.business_category"
                      ref="business_category"
                      placeholder="Industry Type"
                    ></v-select>
                  </v-col>
                </v-row>
                <div class="d-flex justify-end py-6 px-4">
                  <v-btn
                    @click="switchTabs('next')"
                    dark
                    width="121"
                    height="45"
                    style="
                      background: var(--v-primary-base);
                      box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
                      border-radius: 4px;
                    "
                  >
                    <simple-line-icons
                      icon="arrow-right"
                      color="#FFFFFF"
                      style="
                        font-family: simple-line-icons;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 16px;
                      "
                      no-svg
                    />
                    <span
                      class="text-capitalize pl-3"
                      style="
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: center;
                        letter-spacing: 0.636364px;
                        color: #ffffff;
                      "
                      >next</span
                    >
                  </v-btn>
                </div>
              </v-tab-item>
              <!-- BANK TAB -->
              <v-tab-item class="py-7">
                <v-row class="mx-4">
                  <v-col class="px-0"
                    ><v-select
                      :rules="rules.bankname"
                      ref="bankname"
                      v-model="vendorDetails.bankname"
                      background-color="#ffffff"
                      :items="AllBanks || []"
                      :loading="!AllBanks || (AllBanks && AllBanks.length < 1)"
                      item-text="bank"
                      item-value="code"
                      outlined
                      hide-details="auto"
                      placeholder="Bank Name"
                    ></v-select
                  ></v-col>
                </v-row>
                <v-row class="mx-4">
                  <v-col class="pl-0"
                    ><v-text-field
                      v-model="vendorDetails.bankaccount"
                      :rules="rules.bankaccount"
                      ref="bankaccount"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      label="Bank Acccount Number"
                    ></v-text-field
                  ></v-col>
                  <v-col class="pr-0"
                    ><v-text-field
                      v-model="vendorDetails.taxid"
                      :rules="rules.taxid"
                      ref="taxid"
                      background-color="#ffffff"
                      outlined
                      hide-details="auto"
                      label="TIN"
                    ></v-text-field
                  ></v-col>
                </v-row>
                <div class="d-flex justify-end py-6 px-4" v-if="isActive">
                  <v-btn
                    @click="saveAction"
                    :loading="isSending"
                    dark
                    width="121"
                    height="45"
                    style="
                      background: var(--v-primary-base);
                      box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.24);
                      border-radius: 4px;
                    "
                  >
                    <simple-line-icons
                      icon="arrow-right"
                      color="#FFFFFF"
                      style="
                        font-family: simple-line-icons;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 16px;
                      "
                      no-svg
                    />
                    <span
                      class="text-capitalize pl-3"
                      style="
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: center;
                        letter-spacing: 0.636364px;
                        color: #ffffff;
                      "
                      >Save</span
                    >
                  </v-btn>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </div>
      </div>

      <div class="contianerx__footer">
        powered by
        <img :src="require('@/assets/icons/newLogo.svg')" alt="logo" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import contact from "@/api/contact";
import contactDetails from "@/mixins/contactDetails.js";

export default {
  data() {
    return {
      isActive: true,
      orgDetails: {
        orgname: "",
        logo: "",
      },
      isSending: false,

      vendorDetails: {
        address: "",
        contact: {
          first_name: "",
          last_name: "",
          phone: "",
          email: "",
        },
        vendorname: "",
        terms: "",
        zip: "",
        city: "",
        state: "",
        WHT: {
          vendor_type: null,
          category: null,
        },
        bankname: "",
        bankaccount: "",
        taxid: "",
        currency: "",
        business_category: null,
      },

      tab: null,
      VendorTabs: ["Contact", "Business", "Category", "Bank"],

      // rules: {
      //   required: (v) => !!v || "This field is required",
      //   digit: (v) =>
      //     Number.isInteger(Number(v)) || "The value must be an integer number",
      //   email: (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      // },

      rules: {
        first_name: [(v) => !!v || "First Name is required"],
        last_name: [(v) => !!v || "Last Name is required"],
        email: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
        phone: [
          (v) => !!v || "Phone is required",
          (v) => v.length == 11 || "Phone must be 11 digits",
        ],
        address: [(v) => !!v || "Business address is required"],
        vendorname: [(v) => !!v || "Company Name is required"],
        terms: [(v) => !!v || "Payment Terms is required"],
        zip: [(v) => !!v || "Post Code is required"],
        city: [(v) => !!v || "City is required"],
        state: [(v) => !!v || "State is required"],
        vendor_type: [(v) => !!v || "Vendor Type is required"],
        category: [(v) => !!v || "Tax Category is required"],
        currency: [(v) => !!v || "Currency is required"],
        business_category: [(v) => !!v || "Industry Type is required"],
        bankname: [(v) => !!v || "Bank Name is required"],
        bankaccount: [(v) => !!v || "Bank Account is required"],
        taxid: [(v) => !!v || "TIN is required"],
      },
    };
  },

  mixins: [contactDetails],
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),
    viewActiveTab(e) {
      console.log({ e });
    },
    switchTabs(direction) {
      switch (direction) {
        case "next":
          if (this.tab <= this.VendorTabs.length - 2) {
            this.tab++;
            return;
          }
          if (this.tab == this.VendorTabs.length - 1) {
            this.saveAction();
          }
          break;
        default:
          break;
      }
    },

    async fetchAllBanks() {
      try {
        await this.$store.dispatch("transactions/getAllBankNamesAndCodes");
      } catch (error) {
        console.log(error);
      }
    },

    async saveAction() {
      let isInvalidForm = false;

      Object.keys(this.formD).forEach((f) => {
        const result = this.$refs[f].validate(true);
        if (result === false) {
          isInvalidForm = true;
        }
      });

      if (isInvalidForm) {
        this.showToast({
          sclass: "error",
          show: true,
          message: "Please check the form for errors",
          timeout: 3000,
        });
        return;
      }

      this.isSending = true;
      const id = this.$route.params.id;

      try {
        await contact.createNewVendor(id, this.vendorDetails);
        this.showToast({
          sclass: "success",
          show: true,
          message: "Vendor Onboarding was successful..",
          timeout: 3000,
        });
        this.$router.replace("/login");
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: "failed to Onboard vendor",
          timeout: 3000,
        });
      } finally {
        this.isSending = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      AllBanks: "transactions/getAllBanks",
    }),

    //dynamic button size and icon
    size() {
      const size = { xs: "x-small", sm: "small", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    iconSize() {
      const size = { xs: "x-small", sm: "small" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },

    formD() {
      return {
        first_name: this.vendorDetails.contact.first_name,
        last_name: this.vendorDetails.contact.last_name,
        email: this.vendorDetails.contact.email,
        phone: this.vendorDetails.contact.phone,
        address: this.vendorDetails.address,
        vendorname: this.vendorDetails.vendorname,
        terms: this.vendorDetails.terms,
        zip: this.vendorDetails.zip,
        city: this.vendorDetails.city,
        state: this.vendorDetails.state,
        vendor_type: this.vendorDetails.WHT.vendor_type,
        category: this.vendorDetails.WHT.category,
        currency: this.vendorDetails.currency,
        business_category: this.vendorDetails.business_category,
        bankname: this.vendorDetails.bankname,
        bankaccount: this.vendorDetails.bankaccount,
        taxid: this.vendorDetails.taxid,
      };
    },
  },
  async mounted() {
    const otp = this.$route.params.token;

    sessionStorage.setItem("invitetoken", otp);

    this.fetchAllBanks();

    try {
      await this.$store
        .dispatch("organizations/getInviteOrg", this.$route.params.id)
        .then((res) => {
          this.orgDetails = res?.data?.data;
        });

      const { data } = await this.$store.dispatch("contacts/confirmToken", otp);
      if (process.env.NODE_ENV === "development") {
        console.log(data);
      }
    } catch (error) {
      this.showToast({
        sclass: "error",
        show: true,
        message: "otp confirmation error",
        timeout: 3000,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.approval-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__wrapper {
    .page_title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #19283d;
    }
  }

  .contianerx {
    display: inline-block;
    min-width: 425px;
    text-align: center;
    max-width: 768px;
    width: 90%;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
    border-radius: 8px;

    .info_text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 22px;
      color: rgba(25, 40, 61, 0.8);
      margin-bottom: 0px;
      text-align: left;
    }

    &__footer {
      margin: 40px 0 5px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      color: #8f96a1;

      img {
        height: 15px;
      }
    }
  }
}

.action {
  border-radius: 8px;
  background-color: #fff;

  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // mobile view
    @media (max-width: 600px) {
      padding: 15px;
    }

    .b {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .i {
      width: 26px;
      height: 26px;
      object-fit: fit;

      @media (max-width: 600px) {
        width: 20px;
        height: 20px;
      }
    }

    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }

  &__content {
    background-color: #f8f7f4;
    padding: 20px 50px;

    @media (max-width: 600px) {
      padding: 20px 20px;
    }

    .top {
      padding: 30px 0px 0px 0px;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

      @media (max-width: 600px) {
        padding: 10px 0px 0px 0px;
      }

      .action-title {
        color: var(--v-primary-base);
        text-transform: uppercase;
        font-weight: 600;
        border-bottom: 5px solid var(--v-primary-base);
        font-size: 16px;
        height: 40px;
        padding: 0px 15px;
        display: inline-block;

        // mobile view
        @media (max-width: 600px) {
          font-size: 14px;
          font-weight: 500;
          border-bottom: 3px solid var(--v-primary-base);
          height: 35px;
          padding: 0px 10px;
        }
      }
    }

    .action-description {
      color: var(--v-primary-base);
      display: block;
      padding: 20px 0px;
      font-size: 16px;
    }

    .a-wrapper {
      background-color: #fff;
      margin: 50px 20px;
      border-radius: 4px;

      // mobile view
      @media (max-width: 600px) {
        padding: 0px;
      }
    }

    .note {
      display: block;
      margin-top: 10px;
      color: #8f96a1;
    }

    .bottom {
      display: flex;
      justify-content: flex-end;
      margin: 20px 20px;
      gap: 20px;

      // mobile view
      @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}
</style>
